<template>
  <div>
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="ID" horizontal v-if="group.id">{{ group.id }}</b-field>

      <b-field label="Created at" v-if="group.created_at" horizontal>{{
        group.created_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Updated at" v-if="group.updated_at" horizontal>{{
        group.updated_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Name" horizontal>
        <b-input v-if="isEdit" v-model="group.name" type="text"></b-input>
        <span v-else>{{ group.name }}</span>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-if="isEdit" v-model="group.enabled">{{ group.enabled ? 'Yes' : 'No' }}</b-checkbox>
        <span v-else>{{ group.enabled ? 'Yes' : 'No' }}</span>
      </b-field>

      <b-field label="API token" v-if="!isNew" horizontal class="token">
        <ApiToken :value="group.api_token"></ApiToken>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input v-if="isEdit" type="textarea" v-model="group.note" rows="2"></b-input>
        <vue-simple-markdown v-else :source="group.note"></vue-simple-markdown>
      </b-field>

      <b-field label="Codec ID" horizontal>
        <b-input v-if="isEdit && isAdmin" v-model="group.codec_id" type="text"></b-input>
        <span v-else>{{ group.codec_id }}</span>
      </b-field>

      <Billing v-model="group.billing" :organization_id="group.organization_id" :editable="isEdit" v-if="isAdmin">
      </Billing>

      <div class="is-divider"></div>

      <b-field label="Email report" horizontal v-if="groupId">
        <b-table :data="ugrList" striped hoverable bordered>
          <b-table-column field="user.email" label="Email" sortable v-slot="props">
            {{ props.row.user.email }}

            <b-autocomplete
              v-if="props.row.autoCompUsers"
              :data="props.row.autoCompUsers"
              @typing="props.row.onTyping"
              field="option"
              open-on-focus
              placeholder="select user ..."
              clearable
              @select="props.row.onSelect"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-table-column>

          <b-table-column field="user.name" label="User" sortable v-slot="props">
            {{ props.row.user.name }}
          </b-table-column>

          <b-table-column label="Mon" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.mon" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Tue" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.tue" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Wed" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.wed" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Thu" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.thu" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Fri" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.fri" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Sat" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.sat" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column label="Sun" width="20" centered v-slot="props">
            <b-checkbox v-model="props.row.plan.sun" :disabled="!isEdit"></b-checkbox>
          </b-table-column>

          <b-table-column field="action" v-if="isAdmin && isEdit">
            <a v-on:click="removeReportItem(props.row)">
              <b-icon title="Remove" icon="minus-circle" size="is-mediu" type="is-danger"></b-icon>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer" v-if="isEdit">
            <b-button @click="addReportItem" type="is-info" size="is-small" v-if="isAdmin">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add User
            </b-button>
          </template>
        </b-table>
      </b-field>

      <div class="is-divider" v-if="isAdmin"></div>

      <b-field label="Integration" horizontal v-if="isAdmin && group.organization_id">
        <IntegrationList
          v-model="group.integration"
          :organization_id="group.organization_id"
          :editable="isEdit"
        ></IntegrationList>
      </b-field>

      <div class="is-divider"></div>

      <b-field v-if="isBillingChange && !isNew" label="Apply billing changes" horizontal>
        <b-select placeholder="Select ..." required v-model="group.apply_billing">
          <option value="new">Only for new devices</option>
          <option value="update">For all devices in the group, but omit with all with different billing values.</option>
          <option value="force-update">Force update all devices in group</option>
        </b-select>
      </b-field>

      <b-field label horizontal>
        <div class="buttons">
          <b-button v-if="isNew" type="is-success" icon-left="plus-box" native-type="submit">Add Group</b-button>
          <template v-else-if="!isEdit">
            <b-button type="is-info" icon-left="pencil" :disabled="isFetching" v-on:click="edit = true"
              >Edit Group</b-button
            >
            <router-link :to="{ name: 'devices', params: { groupId: group.id }, query: { page: 1 } }">
              <b-button type="is-info" icon-left="database">Show Devices</b-button>
            </router-link>
          </template>
          <template v-else>
            <b-button type="is-info" icon-left="close" :disabled="isFetching" v-on:click="closeEdit">Close</b-button>
            <b-button type="is-success" icon-left="content-save" native-type="submit" :disabled="isFetching"
              >Save Group</b-button
            >
            <b-button type="is-link" v-on:click="showRenewTokenDialog" icon-left="refresh" :disabled="isFetching"
              >Renew Group Token</b-button
            >
            <b-button type="is-danger" v-on:click="showDeleteDialog" icon-left="trash-can" :disabled="isFetching"
              >Delete Group</b-button
            >
          </template>
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this group?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="renewTokenDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Renew token confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeRenewTokenDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to renew API token for this group?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="renewToken">Renew token</button>
          <button class="button" v-on:click="closeRenewTokenDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isEqual, clone, cloneDeep, uniqBy
} from 'lodash';
import { createDiff } from '@/utils';
import ApiToken from '@/components/ApiToken.vue';
import Group from '../models/Group';
import Billing from '../components/Billing.vue';
import IntegrationList from '../components/IntegrationList.vue';

export default {
  name: 'group',
  components: { ApiToken, Billing, IntegrationList },
  data() {
    return {
      edit: false,
      isFetching: false,
      group: {
        enabled: true,
        billing: {},
        organization_id: this.$route.params.organizationId,
      },
      groupOrig: {},
      ugrList: [],
      ugrListOnDelete: [],
      deleteDialog: false,
      renewTokenDialog: false,
      autoCompUsers: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetch();
      this.fetchUGR();
    }
  },
  computed: {
    isNew() {
      return this.group.id === undefined;
    },
    groupId() {
      return this.groupOrig.id || this.$route.params.id;
    },
    userId() {
      return this.$store.state.userId;
    },
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
    profile() {
      return this.$store.state.profile;
    },
    isBillingChange() {
      return !isEqual(this.group.billing, this.groupOrig.billing);
    },
    isEdit() {
      return this.edit || this.isNew;
    },
  },
  methods: {
    updateGroup(group) {
      this.group = group;
      this.groupOrig = cloneDeep(group);
    },
    async fetch() {
      this.isFetching = true;
      const { data } = await Group.getOne(this.groupId);
      if (data.billing === undefined) data.billing = {};
      this.updateGroup(data);
      this.isFetching = false;
    },
    async fetchUGR() {
      this.$http.get('/v1/user-group-report', { params: { group_id: this.groupId } }).then((res) => {
        const { data } = res;
        let find = false;
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].user.id === this.userId) {
            find = true;
          }
          data[i].planOrig = clone(data[i].plan);
        }
        if (!find) {
          data.push({
            user: {
              id: this.userId,
              email: this.profile.email,
              name: this.profile.name,
            },
            plan: {},
            planOrig: {},
          });
        }
        this.ugrList = data;
      });
    },
    async save() {
      const results = [];

      if (!isEqual(this.group, this.groupOrig)) {
        let call;
        const group = this.group.id ? createDiff(this.group, this.groupOrig) : cloneDeep(this.group);

        if (!this.isAdmin && group.billing !== undefined) {
          delete group.billing;
        }
        if (this.group.id) {
          group.id = this.group.id;
          call = Group.update(group).then((resp) => {
            this.updateGroup(resp.data);
          });
        } else {
          call = await Group.add(group).then((resp) => {
            this.updateGroup(resp.data);
            this.$router.push({ name: 'group', params: { id: resp.data.id } }, this.fetchUGR);
          });
        }

        results.push(call);
      }

      for (let i = 0; i < this.ugrList.length; i += 1) {
        const ugr = this.ugrList[i];

        if (!isEqual(ugr.planOrig, ugr.plan)) {
          let call;
          if (ugr.id) {
            call = this.$http.put(`/v1/user-group-report/${ugr.id}`, {
              plan: ugr.plan,
            });
          } else {
            call = this.$http.post('/v1/user-group-report', {
              user_id: ugr.user.id,
              group_id: this.groupId,
              plan: ugr.plan,
            });
          }

          call.then((resp) => {
            ugr.id = resp.data.id;
            ugr.user = resp.data.user;
            ugr.plan = resp.data.plan;
            ugr.planOrig = resp.data.plan;
            if (ugr.autoCompUsers) delete ugr.autoCompUsers;
          });

          results.push(call);
        }
      }

      for (let i = 0; i < this.ugrListOnDelete.length; i += 1) {
        const call = this.$http.delete(`/v1/user-group-report/${this.ugrListOnDelete[i].id}`);
        results.push(call);
      }

      if (results.length > 0) {
        await Promise.all(results);
        this.$toast.success('Group was saved');
        this.ugrListOnDelete = [];
      }
    },
    async deleteMe() {
      if (!this.deleteDialog) return;
      await Group.delete(this.group.id);
      this.closeDeleteDialog();
      this.$toast.success('Group was deleted');
      this.$router.push({
        name: 'groups',
        params: { organizationId: this.group.organization_id },
      });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
    addReportItem() {
      const item = {
        user: {
          name: '',
        },
        plan: {},
        planOrig: {},
        autoCompUsers: this.autoCompUsers || [],
        onTyping: (text) => {
          item.autoCompUsers = this.autoCompUsers.filter((user) => user.option.indexOf(text) > -1);
        },
        onSelect: (option) => {
          item.user.name = option.name;
          item.user.id = option.id;
        },
      };
      this.ugrList.push(item);

      if (this.autoCompUsers === null) {
        this.autoCompUsers = [];
        Promise.all([
          this.$http.get('/v1/users', { params: { organization_id: this.group.organization_id } }),
          this.isAdmin ? this.$http.get('/v1/users', { params: { is_admin: true } }) : { data: [] },
        ]).then((results) => {
          const users = [];
          let items = results[0].data;
          for (let i = 0; i < items.length; i += 1) {
            users.push({
              id: items[i].id,
              name: items[i].name,
              email: items[i].email,
              option: `${items[i].email} ${items[i].name} ${items[i].is_admin ? '(Admin)' : ''}`,
            });
          }

          items = results[1].data;
          for (let i = 0; i < items.length; i += 1) {
            users.push({
              id: items[i].id,
              name: items[i].name,
              email: items[i].email,
              option: `${items[i].email} ${items[i].name} ${items[i].is_admin ? '(Admin)' : ''}`,
            });
          }
          const uniqUsers = uniqBy(users, 'id');
          item.autoCompUsers = uniqUsers;
          this.autoCompUsers = uniqUsers;
        });
      }
    },
    removeReportItem(item) {
      const index = this.ugrList.indexOf(item);
      if (index !== -1) this.ugrList.splice(index, 1);
      if (item.id) {
        this.ugrListOnDelete.push(item);
      }
    },
    showRenewTokenDialog(e) {
      e.preventDefault();
      this.renewTokenDialog = true;
    },
    closeRenewTokenDialog() {
      this.renewTokenDialog = false;
    },
    async renewToken() {
      if (!this.renewTokenDialog) return;
      const { data } = await Group.update({ id: this.group.id, api_token: 'renew' });
      this.$set(this.group, 'api_token', data.api_token);
      this.closeRenewTokenDialog();
      this.$toast.success('Group token was renewed');
    },
    closeEdit() {
      this.edit = false;
      this.fetch();
    },
  },
};
</script>
