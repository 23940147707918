/* eslint-disable no-restricted-syntax */

exports.createDiff = (a, b) => {
  const diff = {};
  for (const key in a) {
    if (a[key] !== b[key]) {
      diff[key] = a[key];
    }
  }
  return diff;
};
